import { createContext, useContext } from 'react';

import { BrandSnippetDecoration } from 'src/models/brandSnippetEditor/brandSnippet.types';

const brandedSnippetContext = createContext<BrandSnippetDecoration | null>(null);

export default brandedSnippetContext;

export const useBrandedSnippetContext = (): BrandSnippetDecoration | null => {
    const value = useContext(brandedSnippetContext);
    return value;
};
