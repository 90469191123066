import { Text, HSpacingContainer, VSpacing } from '@hh.ru/magritte-ui';
import { StarFilledSize16 } from '@hh.ru/magritte-ui/icon';
import Conversion from 'bloko/blocks/conversion';
import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';

import translation from 'src/components/translation';
import useExperiment from 'src/hooks/useExperiment';

interface CompanyReviewRatingProps {
    totalRating: string | undefined;
    reviewsCount: number | undefined;
    isBranded?: boolean;
}

const TrlKeys = {
    reviewsCount: {
        one: 'vacancy.search.employerReviewsCount.one',
        some: 'vacancy.search.employerReviewsCount.some',
        many: 'vacancy.search.employerReviewsCount.many',
    },
};

const CompanyReviewRating: TranslatedComponent<CompanyReviewRatingProps> = ({
    trls,
    totalRating,
    reviewsCount,
    isBranded,
}) => {
    const isExperimentBranded = useExperiment('web_employer_review_card_b', !!isBranded);
    const isExperimentAll = useExperiment('web_employer_review_card_c');

    if (
        !(isExperimentBranded || isExperimentAll) || // Not in any of experiments
        !(totalRating && reviewsCount) // No data to display
    ) {
        return null;
    }

    return (
        <>
            <VSpacing default={4} />
            <HSpacingContainer default={4}>
                <StarFilledSize16 initial="warning-secondary" />
                <Text typography="label-4-regular" style="primary">
                    {totalRating}
                </Text>
                <Text typography="label-4-regular" style="secondary">
                    {'•'}
                </Text>
                <Text typography="label-4-regular" style="secondary">
                    <Conversion
                        one={trls[TrlKeys.reviewsCount.one]}
                        some={trls[TrlKeys.reviewsCount.some]}
                        many={trls[TrlKeys.reviewsCount.many]}
                        value={reviewsCount}
                        hasValue
                    />
                </Text>
            </HSpacingContainer>
        </>
    );
};

export default translation(CompanyReviewRating);
