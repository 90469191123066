import { PictureType, BrandSnippet, PictureAttributes } from 'src/models/brandSnippetEditor/brandSnippet.types';

const getPictureAttributes = (
    branding: BrandSnippet,
    showViewForXs: boolean,
    pictureType: PictureType
): PictureAttributes => {
    const scalable = branding[`${pictureType}Scalable`];
    const xsPicture = branding[`${pictureType}Xs`];
    const defaultPicture = branding[pictureType];

    const pictureScalable = showViewForXs ? scalable?.xs : scalable?.default;
    const pictureNotScalable = showViewForXs ? xsPicture : defaultPicture;

    return {
        src: pictureScalable?.url || pictureNotScalable,
        width: pictureScalable?.width,
        height: pictureScalable?.height,
    };
};

export default getPictureAttributes;
