import { Text } from '@hh.ru/magritte-ui';
import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';

import MetroPoint from 'src/components/MetroPoint';
import translation from 'src/components/translation';
import { VacancyViewMetroStation } from 'src/models/vacancyView.types';
import { NON_BREAKING_SPACE } from 'src/utils/constants/symbols';

type MetroListProps = {
    metroList: VacancyViewMetroStation[];
};

const TrlKeys = {
    moreMetro: 'search.result.item.metro.more',
};

const MetroList: TranslatedComponent<MetroListProps> = ({ metroList, trls }) => {
    if (metroList.length === 0) {
        return null;
    }

    return (
        <>
            <MetroPoint name={metroList[0].name} color={metroList[0].line.color} small isVacancyCard />
            {metroList.length > 1 && (
                <>
                    <Text Element="span" typography="label-3-regular">
                        {NON_BREAKING_SPACE}
                        {trls[TrlKeys.moreMetro]}
                        {NON_BREAKING_SPACE}
                        {metroList.length - 1}
                        {NON_BREAKING_SPACE}
                    </Text>

                    {metroList.map((metro, index) => {
                        if (index === 0) {
                            return null;
                        }
                        return (
                            <MetroPoint key={metro['@id']} color={metroList[index].line.color} small isVacancyCard />
                        );
                    })}
                </>
            )}
        </>
    );
};

export default translation(MetroList);
