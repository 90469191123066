import { HhtmSource } from '@hh.ru/analytics-js-events/build/xhh/applicant/branding/brandy_snippet/brandy_snippet_element_shown';

import { useSelector } from 'src/hooks/useSelector';

interface AnalyticsData {
    hhtmSource: HhtmSource;
    vacancyId: number;
}

const useBrandedSnippetAnalytics = (vacancyId?: number): AnalyticsData => {
    const hhtmSource = useSelector((state) => state.analyticsParams.hhtmSource as HhtmSource);
    return {
        vacancyId: vacancyId ?? 0,
        hhtmSource,
    };
};

export default useBrandedSnippetAnalytics;
