import { FC } from 'react';

import { VSpacing } from '@hh.ru/magritte-ui';

import { useBrandedSnippetContext } from 'src/components/BrandedSnippet/context/BrandedSnippetContext';
import ItAccreditation from 'src/components/Employer/ItAccreditation';
import TrustedEmployerCheck, { TrustedEmployerCheckSize } from 'src/components/Employer/TrustedEmployerCheck';
import { Address as AddressType } from 'src/components/Employer/VacanciesGroupsByRegion/VacancyGroupsTypes';
import EmployerHHRatingIcon from 'src/components/EmployerHHRatingIcon';
import InsiderInterviewLink from 'src/components/InsiderInterviewLink';
import Address from 'src/components/VacancySearchItem/Address';
import CompanyName from 'src/components/VacancySearchItem/CompanyName';
import CompanyReviewRating from 'src/components/VacancySearchItem/CompanyReviewRating';
import EmployerOnline from 'src/components/VacancySearchItem/EmployerOnline';
import HRBrand from 'src/components/VacancySearchItem/HRBrand';
import VacancyLogo from 'src/components/VacancySearchItem/Logo';
import { useCardContainerClassNames } from 'src/components/VacancySearchItem/hooks/useCardContainerClassNames';
import Source from 'src/components/VacancySearchItem/types/Source';
import translation from 'src/components/translation';
import useGetCompanyHHRatingBadge from 'src/hooks/companies/useGetCompanyHHRatingBadge';
import { VacancyCompany as VacancyCompanyType } from 'src/models/vacancySearch/vacancySearchItem.types';
import { VacancyViewInsider } from 'src/models/vacancyView.types';

import styles from './styles.less';

type CompanyProps = {
    company: VacancyCompanyType;
    advClickUrl?: string;
    hrBrand?: {
        type: string;
        '@url': string;
        '@year': number;
    };
    insider?: VacancyViewInsider;
    area?: {
        '@id': number;
        name: string;
    };
    address?: AddressType;
    isEmployerOnline: boolean;
    shouldShowLogo: boolean;
    vacancySource: Source;
    shouldDisableCompanyLink: boolean;
};

const Company: FC<CompanyProps> = ({
    company,
    advClickUrl,
    hrBrand,
    insider,
    area,
    address,
    isEmployerOnline,
    shouldShowLogo,
    vacancySource,
    shouldDisableCompanyLink,
}) => {
    const { wideCardContainerClassName, narrowCardContainerClassName } = useCardContainerClassNames();
    const employerHHRatingBadge = useGetCompanyHHRatingBadge(company?.badges);

    const hasBrandedSnippet = !!useBrandedSnippetContext();

    if (!company?.visibleName) {
        return null;
    }

    const hasAtLeastOneBadge = Boolean(
        company['@trusted'] || company.accreditedITEmployer || hrBrand || employerHHRatingBadge
    );
    const hasInsiderLink = insider && (insider['@isVisible'] || insider.isVisible) && insider.id;

    const badgesElement = (
        <>
            {company['@trusted'] && <TrustedEmployerCheck isMagritte size={TrustedEmployerCheckSize.Small} />}
            {company.accreditedITEmployer && <ItAccreditation isMagritte />}
            {hrBrand && <HRBrand hrBrand={hrBrand} />}
            {employerHHRatingBadge && (
                <EmployerHHRatingIcon badge={employerHHRatingBadge} isMagritte dataQaPrefix="vacancy-serp__vacancy_" />
            )}
            {isEmployerOnline && <EmployerOnline isEmployerOnline={isEmployerOnline} />}
        </>
    );

    const rating = (
        <CompanyReviewRating
            totalRating={company.employerReviews?.totalRating}
            reviewsCount={company.employerReviews?.reviewsCount}
            isBranded={hasBrandedSnippet}
        />
    );

    return (
        <div className={styles.infoSection}>
            <div className={wideCardContainerClassName}>
                <VSpacing default={8} />
                {vacancySource !== Source.EmployerVacancySearch && (
                    <>
                        <VSpacing default={8} />
                        {!hasBrandedSnippet && (
                            <div className={styles.vacancyLogoContainer}>
                                <VacancyLogo company={company} shouldShow={shouldShowLogo} />
                            </div>
                        )}
                        <div className={styles.companyNameBadgesContainer}>
                            <CompanyName
                                company={company}
                                advClickUrl={advClickUrl}
                                isEmployerLinkDisabled={shouldDisableCompanyLink}
                            />

                            {hasAtLeastOneBadge && badgesElement}
                        </div>
                        {rating}
                    </>
                )}
                <Address area={area} address={address} />

                {hasInsiderLink && (
                    <>
                        <VSpacing default={8} />

                        <InsiderInterviewLink insider={insider ?? null} companyId={company.id} isShortText />
                    </>
                )}
            </div>

            <div className={narrowCardContainerClassName}>
                <VSpacing default={12} />
                {vacancySource !== Source.EmployerVacancySearch && (
                    <>
                        <VSpacing default={12} />
                        {hasAtLeastOneBadge && (
                            <>
                                <div className={styles.companyNameBadgesContainer}>{badgesElement}</div>
                                <VSpacing default={4} />
                            </>
                        )}
                        {!hasBrandedSnippet && (
                            <div className={styles.vacancyLogoContainer}>
                                <VacancyLogo company={company} shouldShow={shouldShowLogo} />
                            </div>
                        )}
                        <CompanyName company={company} advClickUrl={advClickUrl} />
                    </>
                )}
                {rating}
                <Address area={area} address={address} />

                {hasInsiderLink && (
                    <>
                        <VSpacing default={12} />

                        <InsiderInterviewLink insider={insider ?? null} companyId={company.id} isShortText />
                    </>
                )}
            </div>
        </div>
    );
};

export default translation(Company);
