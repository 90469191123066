import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';

import translation from 'src/components/translation';
import useGetCompanyUrl from 'src/hooks/companies/useGetCompanyUrl';
import { useIsZarplataPlatform } from 'src/hooks/usePlatform';
import { VacancyProperty } from 'src/models/vacancyProperties';
import { VacancySearchItem } from 'src/models/vacancySearch/vacancySearchItem.types';

import BrandSnippetView from 'src/components/BrandedSnippet/components/BrandSnippetView';
import { useBrandedSnippetContext } from 'src/components/BrandedSnippet/context/BrandedSnippetContext';

const BrandedSnippet: TranslatedComponent<VacancySearchItem> = ({ children, trls, ...vacancy }) => {
    const { company, vacancyId, vacancyProperties } = vacancy;
    const companyUrl = useGetCompanyUrl(company);
    const brandingData = useBrandedSnippetContext();
    const isZpAuction =
        useIsZarplataPlatform() &&
        !!vacancyProperties?.calculatedStates?.HH?.filteredPropertyNames.includes(VacancyProperty.HhAuction);

    if (!brandingData) {
        return <>{children}</>;
    }

    return (
        <BrandSnippetView
            companyName={company.name}
            companyUrl={companyUrl}
            vacancyId={vacancyId}
            isZpAuction={isZpAuction}
            brandingData={brandingData}
        >
            {children}
        </BrandSnippetView>
    );
};

export default translation(BrandedSnippet);
